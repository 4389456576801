<template>
  <section>
    <template v-if="dataLoaded">
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <app-button-delete @deleteRecord="deleteItem"></app-button-delete>

              <router-link
                v-if="item.status !== 3"
                tag="button"
                class="btn btn-success"
                :to="{ name: 'don_npa_edit', params: { id: item.id }, query: { originalPath: this.$route.query.originalPath }}"
              >
                <i class="fa fa-edit"></i> {{ $t('buttons.edit') }}
              </router-link>

              <router-link
                v-if="item.status === 3 && item.duplicate === false"
                tag="button"
                class="btn btn-success"
                :to="{ name: 'don_npa_duplicate', params: { id: item.id }, query: { originalPath: this.$route.query.originalPath }}"
              >
                <i class="fa fa-redo"></i> {{ $t('don.npa.buttons.duplicate') }}
              </router-link>

              <app-button-close :url-path=this.$route.query.originalPath></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <div class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">

            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <table class="table table-striped table-bordered">
            <tbody>
            <tr>
              <th>Id</th>
              <td>{{ item.id }}</td>
            </tr>
            <tr>
              <th>{{ $t('don.npa.title') }}</th>
              <td>{{ item.title }}</td>
            </tr>
            <tr>
              <th>{{ $t('don.npa.url') }}</th>
              <td v-html="item.url"></td>
            </tr>
            <tr>
              <th>{{ $t('don.npa.agency') }}</th>
              <td v-html="item.agency"></td>
            </tr>
            <tr>
              <th>{{ $t('don.npa.client') }}</th>
              <td v-html="item.client"></td>
            </tr>
            <tr>
              <th>{{ $t('don.npa.image') }}</th>
              <td>
                <app-media
                  v-if="mainImage"
                  :media="mainImage"
                  :width="480"
                  :height="270"
                  show-media-info
                >
                </app-media>
              </td>
            </tr>
            <tr>
              <th>{{ $t('don.npa.clicks_limit') }}</th>
              <td v-html="item.clicksLimit"></td>
            </tr>
            <tr>
              <th>{{ $t('don.npa.click_price') }}</th>
              <td v-html="item.clickPrice"></td>
            </tr>
            <tr>
              <th>{{ $t('don.npa.bonus_clicks_limit') }}</th>
              <td v-html="item.bonusClicksLimit"></td>
            </tr>
            <tr>
              <th>{{ $t('don.npa.views') }}</th>
              <td v-html="item.views"></td>
            </tr>
            <tr>
              <th>{{ $t('don.npa.clicks') }}</th>
              <td v-html="item.clicks"></td>
            </tr>
            <tr>
              <th>{{ $t('don.npa.clicks_total_price') }}</th>
              <td v-html="clicksTotalPrice"></td>
            </tr>
            <tr>
              <th>{{ $t('don.npa.bonus_clicks') }}</th>
              <td v-html="item.bonusClicks"></td>
            </tr>
            <tr>
              <th>{{ $t('don.npa.visits') }}</th>
              <td v-html="item.nativePerfViews"></td>
            </tr>
            <tr>
              <th>{{ $t('don.npa.segments') }}</th>
              <td>
                <template v-if="item.segments.length > 0"> {{ item.segments.join(',') }}</template>
                <template v-else> {{ $t('don.npa.all_segments') }}</template>
              </td>
            </tr>
            <tr>
              <th>{{ $t('don.npa.category') }}</th>
              <td v-html="getCategoryTitle(item.category)"></td>
            </tr>
            <tr>
              <th>{{ $t('don.npa.published_since') }}</th>
              <td>{{ item.publishedSince | prettyDateTime }}</td>
            </tr>
            <tr>
              <th>{{ $t('don.npa.published_until') }}</th>
              <td>{{ item.publishedUntil | prettyDateTime }}</td>
            </tr>
            </tbody>
          </table>
          <table class="table table-striped table-bordered" v-if="item.schedulingEnabled">
            <tbody>
            <tr>
              <th>{{ $t('don.npa.campaign_delivery_day') }}</th>
              <td v-html="getDeliveryDay(item.schedulingConfig.deliveryDay)"></td>
            </tr>
            <tr>
              <th>{{ $t('don.npa.campaign_delivered_from') }}</th>
              <td v-html="getDeliveryTime(item.schedulingConfig.deliveryTimeFrom)"></td>
            </tr>
            <tr>
              <th>{{ $t('don.npa.campaign_delivered_to') }}</th>
              <td v-html="getDeliveryTime(item.schedulingConfig.deliveryTimeTo)"></td>
            </tr>
            </tbody>
          </table>
          <table class="table table-striped table-bordered">
            <tbody>
            <tr>
              <th>{{ $t('system.created_at') }}</th>
              <td>{{ item.createdAt | prettyDateTime }}</td>
            </tr>
            <tr>
              <th>{{ $t('system.modified_at') }}</th>
              <td>{{ item.modifiedAt | prettyDateTime }}</td>
            </tr>
            <tr>
              <th>{{ $t('system.created_by') }}</th>
              <td>{{ userNameById(item.createdBy) }}</td>
            </tr>
            <tr>
              <th>{{ $t('system.modified_by') }}</th>
              <td>{{ userNameById(item.modifiedBy) }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="card card-body" v-if="item.alternativesEnabled === true">
        <h3 class="alternating-campaigns-header">{{ $t('don.npa.alternating_campaigns') }}</h3>
        <div v-for="(alternative, idx) in item.articleAlternatives" :key="`item-${idx}`">
          <h4 class="alternating-campaigns-header">
            {{ $t('don.npa.alternative') }}
            <span class="text-uppercase">{{ alternative.name }}</span>
          </h4>
          <table class="table table-striped table-bordered">
            <tbody>
            <tr>
              <th width="280">{{ $t('don.npa.title') }}</th>
              <td>{{ alternative.title }}</td>
            </tr>
            <tr>
              <th>{{ $t('don.npa.image') }}</th>
              <td>
                <app-media
                  v-if="imageAlternatives[idx].image"
                  :media="imageAlternatives[idx].image"
                  :width="480"
                  :height="270"
                  show-media-info
                >
                </app-media>
              </td>
            </tr>
            <tr>
              <th>{{ $t('don.npa.alternative_ctr') }}</th>
              <td>{{ (alternative.ctr * 100) | formatNumber }}%</td>
            </tr>
            <tr>
              <th>{{ $t('don.npa.alternative_in_views_count') }}</th>
              <td>{{ alternative.inViews }}</td>
            </tr>
            <tr>
              <th>{{ $t('don.npa.alternative_clicks_count') }}</th>
              <td>{{ alternative.clicks }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <app-native-perf-article-analytics-chart
            :loading="analyticsDataLoading"
            :data="analyticsData.campaignProgress"
          >
          </app-native-perf-article-analytics-chart>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <app-native-perf-article-analytics-table
            :data="analyticsData.campaignPerformanceBySites"
          >
          </app-native-perf-article-analytics-table>
        </div>
      </div>
    </template>
    <template v-else>
      <app-preloader></app-preloader>
    </template>
  </section>
</template>

<script>
import NativeArticle from '../../model/DonNativePerfArticle'
import ButtonDelete from '../shared/ButtonDelete'
import ButtonClose from '../shared/ButtonClose'
import NotifyService from '../../services/NotifyService'
import Preloader from '../preloader/Preloader'
import UserService from '../../services/user/UserService'
import Media from '../shared/Media'
import { CATEGORIES_MAP } from '@/model/ValueObject/NativePerfCategories'
import CoreApi from '../../api/core'
import { DELIVERIES_LIST } from '@/model/ValueObject/NativePerfDeliveries'
import moment from 'moment'
import NativePerfArticleAnalyticsChart from './NativePerfArticleAnalyticsChart'
import NativePerfArticleAnalyticsTable from './NativePerfArticleAnalyticsTable'

export default {
  name: 'NativePerfArticle',
  data () {
    return {
      item: NativeArticle,
      dataLoaded: false,
      error: null,
      mainImage: null,
      imageAlternatives: [
        {
          name: 'A',
          image: null
        },
        {
          name: 'B',
          image: null
        },
        {
          name: 'C',
          image: null
        }
      ],
      analyticsData: {
        campaignProgress: null,
        campaignPerformanceBySites: []
      },
      analyticsDataLoading: true
    }
  },
  components: {
    appButtonDelete: ButtonDelete,
    appButtonClose: ButtonClose,
    appPreloader: Preloader,
    appMedia: Media,
    appNativePerfArticleAnalyticsChart: NativePerfArticleAnalyticsChart,
    appNativePerfArticleAnalyticsTable: NativePerfArticleAnalyticsTable
  },

  methods: {
    getItem () {
      this.$store.dispatch('nativePerfArticle/fetchOne', this.$route.params.id)
        .then(() => {
          this.item = this.$store.getters['nativePerfArticle/detail']

          this.getAnalyticsData()

          if (this.item.alternativesEnabled) {
            this.loadArticleAlternatives()
          } else {
            this.fetchMainImage(this.item.damImageId)
          }
        })
    },

    getAnalyticsData () {
      this.$store.dispatch('nativePerfAnalytics/getData', this.$route.params.id)
        .then(() => {
          this.analyticsData.campaignProgress = this.$store.getters['nativePerfAnalytics/data'].campaignProgress
          this.analyticsData.campaignPerformanceBySites = this.$store.getters['nativePerfAnalytics/data'].campaignPerformanceBySites
          this.analyticsDataLoading = false
        })
    },

    deleteItem () {
      this.$store.dispatch('nativePerfArticle/deleteRecord', this.item)
        .then(() => {
          if (this.$store.getters['nativePerfArticle/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push(this.$route.query.originalPath)
          } else {
            NotifyService.setErrorMessage(this.$store.getters['nativePerfArticle/error'])
          }
        })
        .catch(error => console.log(error))
    },

    userNameById (id) {
      const user = this.$store.getters['user/userById'](parseInt(id))
      if (user) {
        return UserService.getUserInfo(user)
      }
      return id
    },

    getCategoryTitle (categoryId) {
      return CATEGORIES_MAP[categoryId]
    },

    loadArticleAlternatives () {
      const articleAlternatives = this.item.articleAlternatives
      const articleAlternativesCount = articleAlternatives.length

      const imagePromises = []

      for (let idx = 0; idx < articleAlternativesCount; idx++) {
        const articleAlternative = articleAlternatives[idx]

        const damImageId = articleAlternative.damImageId

        if (damImageId) {
          imagePromises.push(this.fetchArticleAlternativeImage(idx, damImageId))
        }
      }

      Promise.all(imagePromises)
        .finally(result => {
          this.dataLoaded = true
        })
        .catch(err => console.log('Catch', err))
    },

    fetchMainImage (damImageId) {
      return CoreApi().get('/media/' + damImageId)
        .then((response) => {
          this.mainImage = response.data
          this.dataLoaded = true
        })
        .catch(error => console.log(error))
    },

    fetchArticleAlternativeImage (idx, damImageId) {
      return CoreApi().get('/media/' + damImageId)
        .then((response) => {
          if (idx === 0) {
            this.mainImage = response.data
          }

          this.imageAlternatives[idx].image = response.data
        })
        .catch(error => console.log(error))
    },

    getDeliveryDay (deliveryDayId) {
      return DELIVERIES_LIST.find((item) => item.id === deliveryDayId).title
    },

    getDeliveryTime (deliveryTime) {
      return moment.utc(deliveryTime, 'HH:mm').local().format('HH:mm')
    }
  },
  computed: {
    clicksTotalPrice () {
      return this.$options.filters.formatNumber(this.item.clicks * this.item.clickPrice)
    }
  },
  mounted () {
    this.getItem()
  }
}
</script>

<style lang="scss" scoped>
.table-bordered th:first-child {
  width: 200px;
}

.alternating-campaigns-header {
  color: #bbb;
  font-weight: 100;
}
</style>
